// reducers.js

import { SET_CANVAS_LAODER } from '../action/loader.action';

// Initial state
const initialState = {
  canvasLoader: false,
};

// Reducer function
const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANVAS_LAODER:
      return {
        ...state,
        canvasLoader: action.payload,
      };
    default:
      return state;
  }
};

export default loaderReducer;
