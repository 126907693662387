import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createTheme, MantineProvider} from "@mantine/core";
import {ModalsProvider} from "@mantine/modals";
import '@mantine/notifications/styles.css';
import {Notifications} from "@mantine/notifications";

const theme = createTheme({
    /** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MantineProvider theme={theme}>
        <Notifications
            position='top-center'
            zIndex={2077}
        />
        <App />
    </MantineProvider>
);
