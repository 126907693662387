import React, { Suspense } from 'react';
// import Loader from './components/Loader';
// import Launcher from './Launcher/Launcher.jsx';
import { Provider } from 'react-redux';
import store from './redux/store.js';


const AllRoutes = React.lazy(() => import('../src/routes/index.tsx'))

interface AppPageProps {
}

const App: React.FC<AppPageProps> = (props) => {
  return (
    // <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <AllRoutes />
    </Provider>
    
    // <Launcher />
    // </Suspense>
  );
};

export default App;
