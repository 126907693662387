
// Action Types
export const SET_CANVAS_LAODER = 'SET_CANVAS_LAODER';

// Action Creators
export const setCanvasLoader = (name) => {
  return {
    type: SET_CANVAS_LAODER,
    payload: name,
  };
};
